$azul: #64b3f4;

body {
  margin: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  align-items: center;

  .background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form {
    width: 100%;
    background: #fff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 2px 5px 34px 0px rgba(0, 0, 0, 0.33);
  }
}

.hero-section {
  padding: 5px;
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 760px) {
    flex-flow: column;
  }

  h1 {
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0;
  }

  svg {
    fill: #fff;
    width: 60px;
    margin-right: 10px;
  }
}

.your-link {
  .row {
    max-width: 100%;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .copy-to {
    @media screen and (max-width: 760px) {
      flex-flow: column;

      .column {
        max-width: 100%;
      }
    }

    .column {
      margin: 5px;
    }
  }
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  font-size: 1.2rem;
}

.button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $azul;
  border-color: $azul;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
textarea:focus,
select:focus {
  border-color: $azul;
}

.react-phone-number-input__country--native {
  max-height: 37px;
}

.react-phone-number-input__icon:not(.react-phone-number-input__icon--international) {
  border: none;
  align-items: center;
  display: flex;
}

blockquote {
  border-color: $azul;
}

.footer {
  a {
    color: #fff;
    text-align: center;
    display: block;
    padding: 20px;
    max-width: 200px;
    margin: 0 auto;
    &:hover {
      text-decoration: underline;
    }
  }
}
#root {
  // background-image: url(./bg.png);
  background-color: #000;
  background-size: cover;
}

.cls-1{fill:#fff}.cls-2{fill:#febe26}